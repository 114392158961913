//
// autosize.js
// Dashkit module
//

import autosize from 'autosize';

document.addEventListener('turbo:load', function () {
  const toggles = document.querySelectorAll('[data-autosize]');

  toggles.forEach((toggle) => {
    autosize(toggle);
  });

  // Make available globally
  window.autosize = autosize;
});
