import 'bootstrap';
import '@fortawesome/fontawesome-free/js/all';
import '@shopify/draggable/lib/es5/draggable.bundle.legacy.js';
import '@hotwired/turbo-rails';
import './controllers';

import '../assets/javascripts/dashkit/js/autosize.js';
import '../assets/javascripts/dashkit/js/chart.js';
import '../assets/javascripts/dashkit/js/checklist.js';
import '../assets/javascripts/dashkit/js/choices.js';
import '../assets/javascripts/dashkit/js/dropzone.js';
import '../assets/javascripts/dashkit/js/flatpickr.js';
import '../assets/javascripts/dashkit/js/highlight.js';
import '../assets/javascripts/dashkit/js/inputmask.js';
import '../assets/javascripts/dashkit/js/kanban.js';
import '../assets/javascripts/dashkit/js/list.js';
import '../assets/javascripts/dashkit/js/map.js';
import '../assets/javascripts/dashkit/js/popover.js';
import '../assets/javascripts/dashkit/js/quill.js';
import '../assets/javascripts/dashkit/js/tooltip.js';
import '../assets/javascripts/dashkit/js/navbar-collapse.js';
import '../assets/javascripts/dashkit/js/navbar-dropdown.js';
import '../assets/javascripts/dashkit/js/wizard.js';
import '../assets/javascripts/dashkit/js/user.js';

import '../assets/javascripts/app/bulk_actions';
import '../assets/javascripts/app/comment';
import '../assets/javascripts/app/copy';
import '../assets/javascripts/app/feather';
import '../assets/javascripts/app/own_charts';
import '../assets/javascripts/app/paddle/paddle_cancelation_init';
import '../assets/javascripts/app/paddle/paddle_setup';
import '../assets/javascripts/app/paddle/paddle_trial_setup';
import '../assets/javascripts/app/simple_analytics';
import '../assets/javascripts/app/submissions';
import '../assets/javascripts/app/confirm-modal';
import '../assets/javascripts/app/project';
import '../assets/javascripts/app/bootstrap-tags';

import '../assets/stylesheets/application.scss';
