document.addEventListener('turbo:load', () => {
  const bulkButtons = document.querySelectorAll('[data-name="bulk_actions"]');

  bulkButtons.forEach(bulkButton => {
    bulkButton.addEventListener("click", () => {
      const checkboxes = [...document.querySelectorAll('input[name="listCheckbox"]:checked')];
      const queryParams = checkboxes.map(element => `ids[]=${element.value}`).join('&');

      bulkButton.href = `${bulkButton.href}?${queryParams}`;
    });
  });
});
