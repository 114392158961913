//
// inputmask.js
// Theme module
//

import Inputmask from 'inputmask';

document.addEventListener('turbo:load', function () {
  const toggles = document.querySelectorAll('[data-inputmask]');

  const options = {
    rightAlign: false,
  };

  Inputmask(options).mask(toggles);

  // Make available globally
  window.Inputmask = Inputmask;
});
