let PRICE_ITEMS;
let COUNTRIES;

let billingCountry = "US";
let billingCycle = "year";
let priceCache = {};

document.addEventListener('turbo:load', function() {
  const body = document.querySelector('body');

  if (body.dataset.prices) {
    PRICE_ITEMS = JSON.parse(body.dataset.prices);
    COUNTRIES = JSON.parse(body.dataset.countries);
    billingCountry = body.dataset.country || billingCountry;
    const countrySelect = document.getElementById("billing_country");

    getPrices(billingCycle);

    countrySelect.addEventListener("change", () => {
      billingCountry = countrySelect.options[countrySelect.selectedIndex].value;
      getPrices(billingCycle);
    });

    document.getElementById("prices-yearly").addEventListener("click", () => {
      getPrices('year');
    });

    document.getElementById("prices-monthly").addEventListener("click", () => {
      getPrices('month');
    });
  }
});

function getNameByPriceId(cycle, priceId) {
  const item = PRICE_ITEMS[cycle].find(item => item.priceId === priceId);
  return item ? item.name : null;
}

function getFromCache(cycle) {
  return priceCache[billingCountry] && priceCache[billingCountry][cycle];
}

function saveToCache(items, cycle) {
  if (!priceCache[billingCountry]) priceCache[billingCountry] = {};
  priceCache[billingCountry][cycle] = items;
}

function getPrices(cycle) {
  const itemsList = PRICE_ITEMS[cycle];
  const cachedItems = getFromCache(cycle);

  if (cachedItems) {
    updatePrices(cachedItems, cycle);
  } else {
    Paddle.PricePreview({items: itemsList, address: {countryCode: billingCountry}})
      .then(result => {
        const items = result.data.details.lineItems;
        saveToCache(items, cycle);
        updatePrices(items, cycle);
      })
      .catch(console.warn);
  }
}

function formatMonthlyPrice(val) {
  const country = COUNTRIES.find(c => c.code === billingCountry);

  if (country.currencyPosition === 'before') {
    return country.currencyCode + val;
  } else {
    return val + ' ' + country.currencyCode;
  }
}

function updatePrices(items, cycle) {
  if (cycle == 'month') {
    document.getElementById("prices-monthly").classList.add('btn-primary');
    document.getElementById("prices-monthly").classList.remove('btn-white');
    document.getElementById("prices-yearly").classList.remove('btn-primary');
    document.getElementById("prices-yearly").classList.add('btn-white');
  } else {
    document.getElementById("prices-yearly").classList.add('btn-primary');
    document.getElementById("prices-yearly").classList.remove('btn-white');
    document.getElementById("prices-monthly").classList.remove('btn-primary');
    document.getElementById("prices-monthly").classList.add('btn-white');
  }

  items.forEach(item => {
    const planName = getNameByPriceId(cycle, item.price.id);
    const priceLabelId = `${planName}-subtotal`;
    const priceMonthly = document.getElementById(`${planName}-price-monthly`);
    const subtotalMonthly = document.getElementById(`${priceLabelId}-monthly`);
    const subtotalYearly = document.getElementById(`${priceLabelId}-yearly`);
    const priceInDollars = item.unitTotals.subtotal / 100;

    const checkoutBtn = document.getElementById(`${planName}-checkout-btn`);
    if (checkoutBtn) checkoutBtn.setAttribute('data-items', JSON.stringify([{quantity: 1, "priceId": item.price.id}]));

    const migrateBtn = document.getElementById(`${planName}-migrate-btn`);
    if (migrateBtn) migrateBtn.setAttribute('href', '/change_plan?price_id=' + item.price.id);

    if (cycle === 'year') {
      priceMonthly.innerHTML = formatMonthlyPrice((priceInDollars / 12).toFixed(2));

      subtotalYearly.querySelector('.js-total').innerHTML = item.formattedTotals.subtotal;
      subtotalYearly.classList.remove('hidden');

      subtotalMonthly.classList.add('hidden');
    } else {
      priceMonthly.innerHTML = item.formattedTotals.subtotal;

      subtotalYearly.classList.add('hidden');
      subtotalMonthly.classList.remove('hidden');
    }
  });
}
