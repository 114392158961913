import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['authForm'];

  connect() {
  }

  showForm(e) {
    e.preventDefault();
    e.stopImmediatePropagation();
    document.querySelector('.js-auth-email-form').classList.remove("hidden");
    document.querySelector('.js-auth-email-trigger').classList.add("hidden");
  }
}
