import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['form', 'project'];

  connect() {
    if (window.feather) window.feather.replace();
    if (window.initFlatPickr) window.initFlatPickr();
  }

  async sendRequest(item, itemValue) {
    const pathname = window.location.pathname.split('/submissions')[0];
    const filterForm = document.getElementById('filter_form');

    if (!filterForm) return;

    const filtersCount = document.getElementById('filtersCount')?.innerHTML;
    const dates_range = document.getElementById('filter_dates_range')?.value;
    const isVisibleFilterMenu = filterForm.classList.contains('show') ? 'show' : '';
    const params = {
      [`requested_${item}_id`]: itemValue,
      show_filters_menu: isVisibleFilterMenu,
      filters_count: filtersCount,
      dates_range: dates_range
    }
    const queryParams = new URLSearchParams(params).toString();
    const url = `${pathname}/submissions/filter_options?${queryParams}`;
    const response = await fetch(url, {
      headers: {
        'Accept': 'text/vnd.turbo-stream.html',
      }
    });
    const html = await response.text();
    Turbo.renderStreamMessage(html);
  }

  selectForm() {
    this.sendRequest('form', this.formTarget.value);
  }

  selectProject() {
    this.sendRequest('project', this.projectTarget.value);
  }
}
