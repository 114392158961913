function paddleTrialSetup() {
  const body = document.querySelector('body');

  let PRICE_ITEMS = JSON.parse(body.dataset.trialPrices);
  let billingCountry = body.dataset.country || "US";
  let COUNTRIES = JSON.parse(body.dataset.countries);

  function formatMonthlyPrice(val) {
    const country = COUNTRIES.find(c => c.code === billingCountry);

    if (country.currencyPosition === 'before') {
      return country.currencyCode + val;
    } else {
      return val + ' ' + country.currencyCode;
    }
  }

  function updatePrices(items) {
    items.forEach(item => {
      const ourPriceItem = PRICE_ITEMS.find(x => x.priceId === item.price.id);

      const formattedPrice = item.formattedTotals.subtotal;

      if (ourPriceItem.yearly.id) {
        document.getElementById("price_yearly").innerHTML = formattedPrice;
        const priceInDollars = item.unitTotals.subtotal / 100;
        const yearMonthPrice = formatMonthlyPrice((priceInDollars / 12).toFixed(2));
        document.getElementById("price_yearly_month").innerHTML = yearMonthPrice;
      } else {
        document.getElementById("price_monthly").innerHTML = formattedPrice;
      }

      const checkoutBtn = document.getElementById(`${ourPriceItem.slug}-checkout-btn`);
      if (checkoutBtn) checkoutBtn.setAttribute('data-items', JSON.stringify([{quantity: 1, "priceId": item.price.id}]));
    });
  }

  const itemsList = PRICE_ITEMS;

  Paddle.PricePreview({items: itemsList, address: {countryCode: billingCountry}})
    .then(result => {
      const items = result.data.details.lineItems;
      updatePrices(items);
    })
    .catch(console.warn);
}

document.addEventListener('turbo:load', function() {
  const body = document.querySelector('body');

  if (body.dataset.trialPrices) paddleTrialSetup();

  document.getElementById('forced-trial-plan')?.addEventListener('click', function() {
    window.scrollTo({ top: document.getElementById('buttons').offset().top });
  });
});
