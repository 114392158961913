import { Modal } from 'bootstrap';

const buildModal = (message) => {
  const title = window?.App?.confirmOptions?.title || 'Please confirm your action';
  const okText = window?.App?.confirmOptions?.commit || 'Confirm';
  const cancelText = window?.App?.confirmOptions?.cancel || 'Cancel';
  const id = 'turbo-confirm-modal' + String(Math.random()).slice(2, -1);

  const modalElem = document.createElement('div');
  modalElem.id = `"${id}"`;
  modalElem.className = "modal";
  modalElem.innerHTML = `
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 id="${id}Label" class="modal-title">${title}</h5>
        </div>
        <div class="modal-body"></div>
        <div class="modal-footer">
          <button id="modal-btn-descartar" type="button" class="btn btn-secondary" data-bs-dismiss="modal">
            ${cancelText}
          </button>
          <button id="modal-btn-aceptar" type="button" class="btn btn-primary">${okText}</button>
        </div>
      </div>
    </div>
  `

  const body = modalElem.querySelector('.modal-body');

  (message || 'Are you sure?').split(/\n{2}/).forEach(string => {
    body.insertAdjacentHTML('beforeend', `<p>${string}</p>`);
  });

  return modalElem;
};

const bootstrapConfirm = (message, formElement) => {
  const modalElem = buildModal(message);

  const myModal = new Modal(modalElem, {
    keyboard: false,
    backdrop: 'static'
  });
  myModal.show();

  return new Promise((resolve, reject) => {
    document.body.addEventListener('click', response)

    function response(e) {
      let bool = false
      if (e.target.id == 'modal-btn-descartar') bool = false
      else if (e.target.id == 'modal-btn-aceptar') bool = true
      else return

      document.body.removeEventListener('click', response)
      document.body.querySelector('.modal-backdrop')?.remove()
      modalElem.remove()
      resolve(bool)
    }
  })
}

Turbo.setConfirmMethod(bootstrapConfirm);
