const MarkAsRead = {
  init: () => {
    const collapses = document.querySelectorAll(".collapse");
    collapses.forEach(collapse => {
      collapse.addEventListener("shown.bs.collapse", function(e) {
        e.preventDefault();

        const id = e.target.id;

        if (!id?.includes("fullMessage")) return;

        const submissionId = id.replace("fullMessage","");
        const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

        fetch(`/api/submissions/${submissionId}/mark_as_read`, {
          method: "POST",
          headers: {
            "X-CSRF-Token":csrfToken,
            "Accept":"application/json",
            "Content-Type":"application/json"
          },
        }).then(() => document.getElementById(`collapseToggler${submissionId}`).classList.remove("fw-bold"));
      })
    });
  }
}

document.addEventListener('turbo:load', function() {
  if(document.querySelectorAll("#submissions").length > 0) {
    MarkAsRead.init();
  }
});
