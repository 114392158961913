document.addEventListener('turbo:load', () => {
  const selectAllCheckboxes = document.querySelectorAll('.select-all-checkbox');

  selectAllCheckboxes.forEach(selectAllCheckbox => {
    selectAllCheckbox.addEventListener('click', () => {
      const checkboxes = document.querySelectorAll('.project-checkbox');

      checkboxes.forEach(checkbox => {
        checkbox.checked = selectAllCheckbox.checked;
      });
    });
  });
});
