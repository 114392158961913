import { Chart } from 'chart.js';

document.addEventListener('turbo:load', function() {
  function subscribeOnUpdate(toggle)  {
    if (toggle) {
      [].forEach.call(toggle, function(el) {
        el.addEventListener('click', function() {
          if (el.dataset.update) {
            updateOptions(el);
            updateLegend(el);
          }
        });
      });
    }
  }

  function updateLegend(el) {
    var target = el.dataset.target;
    var targetEl = document.querySelector(target);
    var legend = document.getElementById(targetEl.dataset.target);
    var options = JSON.parse(el.dataset.update).data;

    if (!legend) { return }

    while (legend.firstChild) {
      legend.removeChild(legend.firstChild);
    }

    options.labels.slice(0, 3).forEach(function(option, index) {
      var color = options.datasets[0].backgroundColor[index];
      var node = '<span class="chart-legend-item">' +
        '<i class="chart-legend-indicator" ' +
        'style="background-color: ' + color +
        '"></i>' + option + '</span';

      legend.insertAdjacentHTML('beforeend', node);
    })
  }

  function updateOptions(el) {
    var target = el.dataset.target;
    var targetEl = document.querySelector(target);
    var chart = Chart.getChart(targetEl);
    var options = JSON.parse(el.dataset.update).data;

    chart.data.labels = options.labels;
    chart.data.datasets = options.datasets;

    chart.update();
  }

  (function() {
    function init(chart, chartNav) {
      var updateData = JSON.parse(chartNav.dataset.update).data;

      new Chart(chart, {
        type: 'line',
        options: {
          scales: {
            y: {
              gridLines: {//grid
                color: '#283E59',
                zeroLineColor: '#283E59'
              }
            }
          },
          tooltips: {
            callbacks: {
              label: function(item) {
                return '<span class="popover-body-value">' + item.yLabel + '</span>'; //xLabel and yLabel were removed. Please use label and formattedValue
              }
            }
          }
        },
        data: {
          labels: updateData.labels,
          datasets: [{
            data: updateData.datasets[0].data
          }]
        }
      });
    };

    function initSubmissionsChart() {
      var chart = document.getElementById('submissionsChart');
      var chartNav = document.getElementById('submissionsChartNav');
      var toggle = document.querySelectorAll('[data-toggle="submissionsChartToggle"]');

      if (chart && chartNav) {
        init(chart, chartNav, toggle);
        subscribeOnUpdate(toggle);
      }
    };

    if (typeof Chart !== 'undefined') {
      initSubmissionsChart();
    }
  })();

  (function() {
    function init(chart, chartNav) {
      var updateData = JSON.parse(chartNav.dataset.update).data;

      new Chart(chart, {
        type: 'doughnut',
        options: {
          tooltips: {
            callbacks: {
              title: function(item, data) {
                var title = data.labels[item[0].index];
                return title;
              },
              label: function(item, data) {
                var value = data.datasets[0].data[item.index];
                var content = '';

                content += '<span class="popover-body-value">' + value + '%</span>';
                return content;
              }
            }
          }
        },
        data: {
          labels: updateData.labels,
          datasets: updateData.datasets
        }
      });
    };

    function initDevicesChart() {
      var chart = document.getElementById('ownDevicesChart');
      var chartNav = document.getElementById('ownDevicesChartNav');
      var toggle = document.querySelectorAll('[data-toggle="ownDevicesChartToggle"]');

      if (chart && chartNav) {
        init(chart, chartNav);
        subscribeOnUpdate(toggle);
        updateLegend(chartNav);
      }
    };

    if (typeof Chart !== 'undefined') {
      initDevicesChart();
    }
  })();

  (function() {
    var chart = document.getElementById('geo_chart');

    if (chart) {
      google.charts.load('current', {
        packages: ['geochart'],
        mapsApiKey: chart.dataset.apiKey
      });

      google.charts.setOnLoadCallback(drawMap);

      function drawMap() {
        var value = JSON.parse(chart.dataset.value);
        var options = JSON.parse(chart.dataset.options);
        var data = google.visualization.arrayToDataTable(value);
        var googleChart = new google.visualization.GeoChart(chart);

        googleChart.draw(data, options)
      }
    }
  })();
});
