import { Toast } from 'bootstrap';

document.addEventListener('turbo:load', () => {
  const copyButtons = document.querySelectorAll('[data-name="copy"]');

  copyButtons.forEach(copyButton => {
    copyButton.addEventListener("click", event => {
      event.preventDefault();
      let textArea = document.createElement("textarea");
      textArea.value = copyButton.dataset.url;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand("copy");
      document.body.removeChild(textArea);

      const toastContent = document.querySelector('.toast');
      toastContent.querySelector('.toast-body').innerHTML = copyButton.dataset.success;
      const toast = new Toast(toastContent);
      toast.show();
    });
  });
});
