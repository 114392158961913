//
// flatpickr.js
// Theme module
//

import flatpickr from 'flatpickr';

window.initFlatPickr = function() {
  const toggles = document.querySelectorAll('[data-flatpickr]');

  toggles.forEach((toggle) => {
    const options = toggle.dataset.flatpickr ? JSON.parse(toggle.dataset.flatpickr) : {};

    flatpickr(toggle, options);
  });

  // Make available globally
  window.flatpickr = flatpickr;
};

document.addEventListener('turbo:load', function () {
  window.initFlatPickr();
});
